* {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.featureText {
  position: relative;
  z-index: -1;
  bottom: -350px;
  transition: all 0.6s ease;
}
.feature:hover .featureText {
  bottom: 0px;
}
.featureHeading {
  margin-top: 90px;
  position: relative;
  transition: all 0.6s ease;
}
.feature:hover .featureHeading {
  margin-top: 60px;
}
.featureImg {
  transition: all 0.6s ease;
}
.feature:hover .featureImg {
  filter: brightness(55%);
}

.feature:hover .featurePress {
  display: none;
}
