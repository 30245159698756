@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #fcfcfc;
}

.container {
  /* max-width: 1100px; */
  /* margin: 0 auto; */
  /* padding: 0 15px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  /* border: 1px solid red; */
}

/* .navbar {
  height: 60px;
  background-color: #f8f3fe;
  position: relative;
} */
.navbarcolor {
  height: 60px;
  background-color: rgb(246, 250, 246);
  position: relative;
}

.logo {
}

.menu-icon {
  display: none;
}

.nav-elements {
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 35px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
  padding-bottom: 10px;
  transition: all 0.1s ease-in-out;
  &:hover {
    border-bottom: 2px solid green;
    color: green;
  }
}

.nav-elements ul a.active {
  color: green;
  /* font-weight: 500; */
  position: relative;
  border-bottom: 2px solid green;
}

/* .nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: green;
} */

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 1000px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: rgb(246, 250, 246);

    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 1;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    align-items: start;
    /* background-color: #574c4c; */
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
    margin-left: 20px;
  }
}
